import React from 'react';
import { Alert, Card } from 'antd';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Puedes logear el error a un servicio de reporte de errores aquí
		console.error('Error capturado:', error, errorInfo);
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
	}

	handleReload = () => {
		window.location.reload();
	};

	render() {
		if (this.state.hasError) {
			return (
				<Card className='mt-4'>
					<Alert
						message='Ha ocurrido un error'
						description={
							<div>
								<p>
									Lo sentimos, ha ocurrido un error en esta sección.
								</p>
								<p>Detalles del error: {this.state.error?.message}</p>
								<button
									onClick={this.handleReload}
									className='ant-btn ant-btn-primary mt-3'
								>
									Recargar página
								</button>
							</div>
						}
						type='error'
						showIcon
					/>
				</Card>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
