import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch } from 'react-redux';
import ErrorBoundary from 'utils/ErrorBoundaryComponent';

const AppRoute = ({
	component: Component,
	routeKey,
	blankLayout,
	...props
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const isBlank = blankLayout ? true : false;
		dispatch(onBlankLayout(isBlank));
	}, [blankLayout]);

	return (
		<ErrorBoundary key={routeKey}>
			<Component {...props} />
		</ErrorBoundary>
	);
};

export default AppRoute;
